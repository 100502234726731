import { numberToColorType } from "@/utils";
import { Typography, TypographyProps } from "@mui/material";

import { Currency } from "./currency";

export function Difference({
    value,
    ...props
}: TypographyProps & {
    value: number;
}) {
    const sign = value > 0 ? "+" : "";
    return (
        <Typography
            sx={{
                color: numberToColorType(value),
            }}
            {...props}
        >
            {sign}
            <Currency value={value} />
        </Typography>
    );
}
