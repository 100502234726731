import { Grid2, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { useQuery } from "@tanstack/react-query";
import { Icon } from "@theme/icon";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { getGlobalApiClient } from "../../api";
import { companyNameAtom, customerNameAtom } from "../../atoms";
import { CompanyAccordion } from "./company-accordion";

type Company = {
    access_date: string;
    cash_customer: string;
    company_code: string;
    company_id: string;
    company_name: string;
    created_at: string;
    record_sequence_number: number;
    status_indication: number;
    updated_at: null | string;
};

type Customer = {
    companies: Company[];
    id: string;
    name: string;
};

export function CompanySelect() {
    const { t } = useTranslation("root");
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(
        null,
    );
    const open = Boolean(anchorElement);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorElement(null);
    };

    const customersQuery = useQuery({
        queryFn: async () => {
            const client = await getGlobalApiClient();
            return await client.get_company({
                cash_customer: "00000000-0000-0000-0000-000000000000",
            });
        },
        queryKey: ["companies"],
    });

    const customers = [
        {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            companies: customersQuery.data?.data?.items ?? [],
            id: "00000000-0000-0000-0000-000000000000",
            name: t("company-switcher.companyEnvironment"),
        },
    ];

    const companyName = useAtomValue(companyNameAtom);
    const customerName = useAtomValue(customerNameAtom);

    return (
        <>
            <Button
                aria-controls={open ? "company-switcher" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                id="company-switcher-button"
                onClick={handleClick}
                sx={{ color: "white" }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                >
                    <Icon color="white" name="FolderBuilding" size="sm" />

                    <Stack>
                        <span style={{ textAlign: "left" }}>{companyName}</span>

                        <span
                            style={{
                                fontSize: "14px",
                                fontWeight: 400,
                                marginTop: "5px",
                                textAlign: "left",
                            }}
                        >
                            {customerName}
                        </span>
                    </Stack>

                    <Icon name="ChevronDown" size="sm" />
                </Stack>
            </Button>
            <Menu
                anchorEl={anchorElement}
                id="company-switcher"
                MenuListProps={{
                    "aria-labelledby": "company-switcher-button",
                }}
                onClose={handleClose}
                open={open}
            >
                <Grid2 maxHeight={"80vh"} p={4}>
                    <Grid2>
                        {customers.map((customer: Customer) => (
                            <CompanyAccordion
                                companies={customer.companies}
                                customer={customer}
                                handleClose={handleClose}
                                key={customer.id}
                            />
                        ))}
                    </Grid2>
                </Grid2>
            </Menu>
        </>
    );
}
