import { createRoute } from "@tanstack/react-router";

import UserOverviewPage from "../pages/user-overview-page";
import rootRoute from "./__root";

const userOverviewRoute = createRoute({
    component: UserOverviewPage,
    getParentRoute: () => rootRoute,
    path: "/user/overview",
});

export default userOverviewRoute;
