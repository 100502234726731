import { darkNeutral, lightNeutral } from "@/theme/colors";
import {
    List,
    ListItem,
    ListItemButton,
    styled,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ActiveListItemButton = styled(ListItemButton)(({ theme }) => ({
    "&:hover": {
        background: theme.palette.primary.main,
    },
    background: theme.palette.primary.main,
    borderRadius: "10px",
    color: "#fff",
}));

export function SubNavigationCategoryList({
    hasMenuItems,
    items,
    setActiveMenu,
    setMenuContent,
}: {
    hasMenuItems: boolean;
    items: { active?: boolean; i18n_tag: string; label: string }[];
    setActiveMenu: React.Dispatch<React.SetStateAction<null | string>>;
    setMenuContent: (category: string) => void;
}) {
    const { t } = useTranslation("root");
    return (
        <>
            {hasMenuItems ? (
                <List
                    sx={(theme) => ({
                        backgroundColor:
                            theme.palette.mode === "dark"
                                ? darkNeutral[100.1]
                                : lightNeutral[100.1],
                        fontSize: "14px",
                        fontWeight: "bold",
                        overflow: "hidden",
                        padding: "1em",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    })}
                >
                    {items.map((item) =>
                        item.active ? (
                            <ActiveListItemButton
                                key={item.label}
                                onClick={() => {
                                    setActiveMenu(item.label);
                                    setMenuContent(item.label);
                                }}
                                sx={{ paddingY: "1em" }} // weird hack to make padding work
                            >
                                {t(`mega-menu.${item.i18n_tag}`, item.label)}
                            </ActiveListItemButton>
                        ) : (
                            <ListItemButton
                                key={item.label}
                                onClick={() => {
                                    setActiveMenu(item.label);
                                    setMenuContent(item.label);
                                }}
                                sx={{ borderRadius: "10px", paddingY: "1em" }}
                            >
                                {t(`mega-menu.${item.i18n_tag}`, item.label)}
                            </ListItemButton>
                        ),
                    )}
                </List>
            ) : (
                <List sx={{ paddingRight: "2em" }}>
                    {items?.map((item, index) => (
                        <ListItem key={index}>
                            <Typography color="textPrimary" variant="body2">
                                {item?.label}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            )}
        </>
    );
}
