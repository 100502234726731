import { lightNeutral } from "@/theme/colors";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";

const StyledTableCell = ({
    columnId,
    value,
}: {
    columnId: string;
    value: string;
}) => {
    const style = {
        ...(value.startsWith("-") ? { color: "red" } : undefined),
        ...(columnId === "voucher_number" ? { color: "#1482CC" } : undefined),
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    };

    return <TableCell style={style}>{value}</TableCell>;
};

export type Journals = {
    items: LedgerTransaction[];
    journal_name: string;
    total_amount_credit: string;
    total_amount_debet: string;
};

export type JournalTableColumn = {
    id: keyof LedgerTransaction;
    label: string;
};

type JournalTableProps = {
    columns: JournalTableColumn[];
    data: Journals[];
};

type LedgerTransaction = {
    amount: string;
    amount_offset_account: string;
    book_date: string;
    book_period: string;
    characteristic: string;
    credit: string;
    debet: string;
    description: string;
    incasso_code: string;
    ledger_account: string;
    pairing_code: string;
    search_name: string;
    voucher_number: string;
};

const JournalTable = ({ columns, data }: JournalTableProps) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.id}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((journalGroup, index) => (
                        <React.Fragment key={`journal-${index}`}>
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    sx={() => ({
                                        borderBottom: `2px solid ${lightNeutral[150]}`,
                                        fontWeight: "bold",
                                        marginLeft: "16px",
                                    })}
                                >
                                    {journalGroup.journal_name}
                                </TableCell>
                            </TableRow>
                            {journalGroup.items.map((journal, indexIndex) => (
                                <TableRow key={`${index}-${indexIndex}`}>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            columnId={column.id}
                                            key={column.id}
                                            value={journal[column.id]}
                                        />
                                    ))}
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    style={{
                                        borderBottom: "2px solid #F0F2F5",
                                    }}
                                ></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length - 5}
                                ></TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>
                                    Total
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>
                                    {journalGroup.total_amount_debet}
                                </TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>
                                    {journalGroup.total_amount_credit}
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default JournalTable;
