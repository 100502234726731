import { JournalOverviewPage } from "@/pages/journal-overview-page";
import { createRoute } from "@tanstack/react-router";

import rootRoute from "./__root";

const journalOverviewRoute = createRoute({
    component: JournalOverviewPage,
    getParentRoute: () => rootRoute,
    path: "/journal/overview",
});

export default journalOverviewRoute;
