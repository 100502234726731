import JournalTable, {
    Journals,
    JournalTableColumn,
} from "@/components/journal-overview/journal-table";
import { Box, Card } from "@mui/material";

const journalData: Journals[] = [
    {
        items: [
            {
                amount: "",
                amount_offset_account: "",
                book_date: "01-01-2024",
                book_period: "2401",
                characteristic: "000001-548548",
                credit: "",
                debet: "200",
                description: "GESKUS",
                incasso_code: "",
                ledger_account: "1300",
                pairing_code: "",
                search_name: "GESKUS",
                voucher_number: "000001",
            },
            {
                amount: "",
                amount_offset_account: "",
                book_date: "01-01-2024",
                book_period: "2401",
                characteristic: "",
                credit: "165,29",
                debet: "",
                description: "GESKUS",
                incasso_code: "",
                ledger_account: "8100",
                pairing_code: "",
                search_name: "OMZET",
                voucher_number: "000001",
            },
            {
                amount: "-165,29",
                amount_offset_account: "",
                book_date: "01-01-2024",
                book_period: "2401",
                characteristic: "",
                credit: "37,71",
                debet: "",
                description: "GESKUS",
                incasso_code: "",
                ledger_account: "1700",
                pairing_code: "",
                search_name: "OBVRKHOOG",
                voucher_number: "000001",
            },
            {
                amount: "",
                amount_offset_account: "",
                book_date: "01-01-2024",
                book_period: "2401",
                characteristic: "596031-012455",
                credit: "",
                debet: "500",
                description: "TESTQR",
                incasso_code: "",
                ledger_account: "1300",
                pairing_code: "",
                search_name: "TESTQR",
                voucher_number: "000002",
            },
            {
                amount: "",
                amount_offset_account: "",
                book_date: "01-01-2024",
                book_period: "2401",
                characteristic: "",
                credit: "413,22",
                debet: "",
                description: "TESTQR",
                incasso_code: "",
                ledger_account: "8000",
                pairing_code: "",
                search_name: "OMZET",
                voucher_number: "000002",
            },
            {
                amount: "-413,22",
                amount_offset_account: "",
                book_date: "01-01-2024",
                book_period: "2401",
                characteristic: "",
                credit: "86,78",
                debet: "",
                description: "TESTQR",
                incasso_code: "",
                ledger_account: "1700",
                pairing_code: "",
                search_name: "OBVRKHOOG",
                voucher_number: "000002",
            },
            {
                amount: "",
                amount_offset_account: "",
                book_date: "01-01-2024",
                book_period: "2401",
                characteristic: "596031-000003",
                credit: "",
                debet: "5.444,00",
                description: "TESTQR",
                incasso_code: "",
                ledger_account: "1300",
                pairing_code: "",
                search_name: "TESTQR",
                voucher_number: "000003",
            },
            {
                amount: "",
                amount_offset_account: "",
                book_date: "01-01-2024",
                book_period: "2401",
                characteristic: "",
                credit: "4.499,17",
                debet: "",
                description: "TESTQR",
                incasso_code: "",
                ledger_account: "8000",
                pairing_code: "",
                search_name: "OMZET",
                voucher_number: "000003",
            },
            {
                amount: "-4.499,17",
                amount_offset_account: "",
                book_date: "01-01-2024",
                book_period: "2401",
                characteristic: "",
                credit: "944,83",
                debet: "",
                description: "TESTQR",
                incasso_code: "",
                ledger_account: "1700",
                pairing_code: "",
                search_name: "OBVRKHOOG",
                voucher_number: "000003",
            },
        ],
        journal_name: "Verkopen",
        total_amount_credit: "6.144,00",
        total_amount_debet: "6.144,00",
    },
    {
        items: [
            {
                amount: "",
                amount_offset_account: "-2684,91",
                book_date: "03-04-2024",
                book_period: "2404",
                characteristic: "",
                credit: "",
                debet: "3.753,70",
                description: "",
                incasso_code: "",
                ledger_account: "1100",
                pairing_code: "",
                search_name: "",
                voucher_number: "240403",
            },
            {
                amount: "",
                amount_offset_account: "",
                book_date: "03-04-2024",
                book_period: "2404",
                characteristic: "",
                credit: "",
                debet: "129,93",
                description: "ASR Kenmerk: ;2103902918",
                incasso_code: "",
                ledger_account: "9999B",
                pairing_code: "",
                search_name: "MATCHEN",
                voucher_number: "240403",
            },
            {
                amount: "",
                amount_offset_account: "",
                book_date: "03-04-2024",
                book_period: "2404",
                characteristic: "",
                credit: "",
                debet: "8,21",
                description: "YELLOWBRICK BY BUCKAROO",
                incasso_code: "",
                ledger_account: "9999B",
                pairing_code: "",
                search_name: "MATCHEN",
                voucher_number: "240403",
            },
            {
                amount: "",
                amount_offset_account: "",
                book_date: "03-04-2024",
                book_period: "2404",
                characteristic: "",
                credit: "",
                debet: "81,08",
                description: "Fieten",
                incasso_code: "",
                ledger_account: "9999B",
                pairing_code: "",
                search_name: "MATCHEN",
                voucher_number: "240403",
            },
            {
                amount: "",
                amount_offset_account: "",
                book_date: "03-04-2024",
                book_period: "2404",
                characteristic: "",
                credit: "3.972,92",
                debet: "",
                description: "correctie dagboek",
                incasso_code: "",
                ledger_account: "9999",
                pairing_code: "",
                search_name: "UITZOEKEN",
                voucher_number: "240403",
            },
        ],
        journal_name: "ABN AMRO Bank",
        total_amount_credit: "3.972,92",
        total_amount_debet: "3.972,92",
    },
];

const columns: JournalTableColumn[] = [
    { id: "voucher_number", label: "stuk nummer" },
    { id: "book_date", label: "boek datum" },
    { id: "ledger_account", label: "grootb rek" },
    { id: "pairing_code", label: "kpl code" },
    { id: "search_name", label: "zoeknaam" },
    { id: "characteristic", label: "kenmerk" },
    { id: "incasso_code", label: "foutcode incasso" },
    { id: "description", label: "omschrijving" },
    { id: "book_period", label: "boek per" },
    { id: "debet", label: "debet" },
    { id: "credit", label: "credit" },
    { id: "amount_offset_account", label: "saldo tegenrek" },
    { id: "amount", label: "aantal" },
];

export function JournalOverviewPage() {
    return (
        <Box
            sx={(theme) => ({
                backgroundColor: theme.palette.background.admin,
                backgroundSize: "cover",
                height: "calc(100vh - 64px - 48px - 10px)",
                overflow: "auto",
                position: "relative",
                width: "100%",
            })}
        >
            <Card
                sx={(theme) => ({
                    alignItems: "flex-start",
                    backgroundColor: theme.palette.background.container,
                    borderRadius: "8px",
                    boxShadow: "0px 1px 1px 0px rgba(22,62,89,0.10)",
                    height: "calc(100% - 64px)",
                    margin: "32px 75px 32px 75px",
                    overflow: "auto",
                    position: "relative",
                    width: "calc(100% - 150px)",
                })}
            >
                <JournalTable columns={columns} data={journalData} />
            </Card>
        </Box>
    );
}
