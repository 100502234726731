import { createRoute } from "@tanstack/react-router";

import { BalancePage } from "../pages/balance-page";
import rootRoute from "./__root";

const balanceRoute = createRoute({
    component: BalancePage,
    getParentRoute: () => rootRoute,
    path: "/balance",
});

export default balanceRoute;
