import { Icon } from "@/theme/icon";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";

import { CancelButton, SaveButton } from "../general/buttons";

const OptionDrawerContent = () => (
    <Box sx={{ marginLeft: "38px" }}>
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                minWidth: "400px",
            }}
        >
            <Typography sx={{ fontSize: "20px" }} variant={"h2"}>
                Over deze gebruiker
            </Typography>
        </Box>
        <Stack spacing={1} sx={{ marginTop: "16px" }}>
            <Typography variant="h1">
                <Icon name="File" size="xs" />
                <div style={{ marginLeft: 16 }}>Stagiairtje</div>
            </Typography>

            <Typography variant="h1">
                <Icon name="UserLight" size="xs" />
                <div style={{ marginLeft: 16 }}>Light User</div>
            </Typography>
            <Box alignItems="center" display="flex">
                <Typography variant="h1">
                    <Icon name="Email" size="xs" />
                    <div style={{ marginLeft: 16 }}>stagiairtje@bedrijf.nl</div>
                </Typography>
            </Box>
            <Typography variant="h1">
                <Icon name="History" size="xs" />
                <div style={{ marginLeft: 16 }}>
                    Laatst actief op 12-09-2024
                </div>
            </Typography>
        </Stack>
        <Divider
            sx={{
                borderBottomWidth: 2,
                borderColor: "#A5A5A5",
                borderStyle: "dotted",
                my: 3,
            }}
        />
        <Box>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="h2">Rollen en rechten</Typography>
                <Button
                    color="primary"
                    onClick={(event) => event.stopPropagation()}
                    size="small"
                    startIcon={<Icon name="EditFile" size="xs" />}
                >
                    Beheren
                </Button>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                mt={1}
                sx={{ marginTop: "16px" }}
            >
                <Typography variant="h1">
                    <Icon name="RibbonMedal" size="xs" />
                    <div style={{ marginLeft: 16 }}>Hoofdgebruiker</div>
                </Typography>
            </Box>
        </Box>
        <Divider
            sx={{
                borderBottomWidth: 2,
                borderColor: "#A5A5A5",
                borderStyle: "dotted",
                my: 3,
            }}
        />
        <Box>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="h2">Administraties</Typography>
                <Button
                    color="primary"
                    onClick={(event) => event.stopPropagation()}
                    size="small"
                    startIcon={<Icon name="EditFile" size="xs" />}
                >
                    Beheren
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography sx={{ marginTop: "16px" }} variant="h1">
                    <div>
                        Deze gebruiker heeft toegang tot de volgende
                        administraties:
                    </div>
                </Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                mt={1}
                sx={{ marginTop: "16px" }}
            >
                <Typography variant="h1">
                    <Icon name="ImportCompany" size="xs" />
                    <div style={{ marginLeft: 16 }}>Bedrijfsnaam B.V.</div>
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="h1">
                    <Icon name="ImportCompany" size="xs" />
                    <div style={{ marginLeft: 16 }}>Ander bedrijf B.V.</div>
                </Typography>
            </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
            display="flex"
            gap={2}
            justifyContent="flex-end"
            mt={2}
            sx={{ marginTop: "60px" }}
        >
            <CancelButton />
            <SaveButton />
        </Box>
    </Box>
);

export default OptionDrawerContent;
