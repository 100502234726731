import type { ThemeOptions } from "@mui/material";

export const typography: ThemeOptions["typography"] = {
    fontFamily: "Open Sans",
    h1: {
        alignItems: "center",
        color: "primary",
        display: "flex",
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontWeight: 400,
    },
    h2: {
        color: "primary",
        fontFamily: "Ubuntu",
        fontSize: "16px",
        fontWeight: 500,
    },
    h3: {
        fontFamily: "Ubuntu",
    },
    h4: {
        fontFamily: "Ubuntu",
    },
    h5: {
        fontFamily: "Ubuntu",
    },
    h6: {
        fontFamily: "Ubuntu",
    },
};
