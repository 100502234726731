import type { Components } from "globalapi.openapi";

import { Grid2 } from "@mui/material";
import { useTranslation } from "react-i18next";

import { MegaMenuColumn } from "./megamenu-column";
import { SubNavigationCategoryList } from "./subnavigation-categorylist";

export default function MenuContent({
    activeMenu,
    handleSubMenuClick,
    hasMenuItems,
    menu,
    menuContent,
    menuKey,
    setMenuContentByKey,
}: {
    activeMenu: null | string;
    handleSubMenuClick: (label: string) => void;
    hasMenuItems: boolean;
    menu?: Components.Schemas.MenuStructureResponse;
    menuContent: Components.Schemas.MenuStructureResponse[];
    menuKey: null | string;
    setMenuContentByKey: (category: string) => void;
}) {
    const { t } = useTranslation("root");
    const items =
        menu?.menuItems
            ?.find((item) => item.menuItem?.label === menuKey)
            ?.menuItems?.map((subMenu) => {
                return {
                    active: subMenu.menuItem?.label === activeMenu,
                    i18n_tag: subMenu.menuItem?.i18n_tag ?? "",
                    label: t(
                        `mega-menu.${subMenu.menuItem?.i18n_tag}`,
                        subMenu.menuItem?.label ?? "",
                    ),
                };
            }) ?? [];

    return (
        <Grid2
            container
            spacing={1}
            sx={{ minWidth: hasMenuItems ? "650px" : "240px" }}
        >
            <Grid2 size={hasMenuItems ? 6 : 15}>
                <SubNavigationCategoryList
                    hasMenuItems={hasMenuItems}
                    items={items}
                    setActiveMenu={(value) => {
                        if (typeof value === "string") {
                            handleSubMenuClick(value);
                        }
                    }}
                    setMenuContent={setMenuContentByKey}
                />
            </Grid2>
            {hasMenuItems && (
                <Grid2 size={6}>
                    <MegaMenuColumn
                        column={{
                            items: menuContent,
                            title: activeMenu ?? undefined,
                        }}
                    />
                </Grid2>
            )}
        </Grid2>
    );
}
