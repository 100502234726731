import { ChevronDown } from "@/theme/icons";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { Icon } from "@theme/index";

const UsernameCell = ({
    disabled,
    parameters,
    setHoveredRow,
}: {
    disabled: boolean;
    parameters: GridRenderCellParams;
    setHoveredRow: (id: null | number) => void;
}) => (
    <div
        onClick={(event) => event.stopPropagation()}
        onMouseEnter={() => !disabled && setHoveredRow(parameters.id as number)}
        onMouseLeave={() => !disabled && setHoveredRow(null)}
        style={{ alignItems: "center", display: "flex" }}
    >
        {parameters.value}
    </div>
);

const TypeCell = ({
    disabled,
    parameters,
    setHoveredRow,
}: {
    disabled: boolean;
    parameters: GridRenderCellParams;
    setHoveredRow: (id: null | number) => void;
}) => (
    <div
        onClick={(event) => event.stopPropagation()}
        onMouseEnter={() => !disabled && setHoveredRow(parameters.id as number)}
        onMouseLeave={() => !disabled && setHoveredRow(null)}
        style={{ alignItems: "center", display: "flex" }}
    >
        <Icon
            name={parameters.value === "Light" ? "UserFull" : "UserLight"}
            size="xs"
        />
        <span style={{ marginLeft: 8 }}>{parameters.value}</span>
    </div>
);

const ProfileCell = ({
    disabled,
    handleProfileChange,
    hoveredRow,
    parameters,
    setHoveredRow,
}: {
    disabled: boolean;
    handleProfileChange: (
        event: SelectChangeEvent<string>,
        parameters: GridRenderCellParams,
    ) => void;
    hoveredRow: null | number;
    parameters: GridRenderCellParams;
    setHoveredRow: (id: null | number) => void;
}) => (
    <div
        onClick={(event) => event.stopPropagation()}
        onMouseEnter={() => !disabled && setHoveredRow(parameters.id as number)}
        onMouseLeave={() => !disabled && setHoveredRow(null)}
        style={{
            height: "100%",
            margin: 0,
            padding: 0,
            position: "relative",
            width: "100%",
        }}
    >
        {hoveredRow === parameters.id ? (
            <Select
                disabled={disabled}
                fullWidth
                IconComponent={ChevronDown}
                onChange={(event) => handleProfileChange(event, parameters)}
                sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    "& .MuiSelect-select": {
                        alignItems: "center",
                        display: "flex",
                        padding: 0,
                    },
                }}
                value={parameters.value as string}
            >
                <MenuItem value="Ux Designer">Ux Designer</MenuItem>
                <MenuItem value="Profiel B">Profiel B</MenuItem>
                <MenuItem value="Profiel C">Profiel C</MenuItem>
                <MenuItem value="Geen profiel">Geen profiel</MenuItem>
            </Select>
        ) : (
            <div>{parameters.value}</div>
        )}
    </div>
);

const InfoCell = ({
    disabled,
    handleToggleDrawer,
    hoveredRow,
    isEditMode,
    parameters,
    setHoveredRow,
    showInfoIcon,
}: {
    disabled: boolean;
    handleToggleDrawer: () => void;
    hoveredRow: null | number;
    isEditMode: boolean;
    parameters: GridRenderCellParams;
    setHoveredRow: (id: null | number) => void;
    showInfoIcon: boolean;
}) => (
    <div
        onClick={(event) => event.stopPropagation()}
        onMouseEnter={() => !disabled && setHoveredRow(parameters.id as number)}
        onMouseLeave={() => !disabled && setHoveredRow(null)}
        style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            width: "100%",
        }}
    >
        {(hoveredRow === parameters.id || showInfoIcon) && (
            <Button
                disabled={disabled}
                onClick={(event) => {
                    event.stopPropagation();
                    handleToggleDrawer();
                }}
                sx={{
                    alignItems: "center",
                    display: "flex",
                    minWidth: "auto",
                    padding: 0,
                }}
            >
                {isEditMode ? (
                    <Icon color="#1482CC" name="InfoFill" size="xs" />
                ) : (
                    <>
                        <Icon color="#1482CC" name="Info" size="xs" />
                        <span style={{ color: "#1482CC", marginLeft: 4 }}>
                            Informatie
                        </span>
                    </>
                )}
            </Button>
        )}
    </div>
);

const UserOverviewDataGrid = ({
    handleProfileChange,
    handleToggleDrawer,
    hoveredRow,
    isEditMode,
    rows,
    setHoveredRow,
    showInfoIcon,
}: {
    handleProfileChange: (
        event: SelectChangeEvent<string>,
        parameters: GridRenderCellParams,
    ) => void;
    handleToggleDrawer: () => void;
    hoveredRow: null | number;
    isEditMode: boolean;
    rows: Array<{
        id: number;
        profile: string;
        type: string;
        username: string;
    }>;
    setHoveredRow: (id: null | number) => void;
    showInfoIcon: null | number;
}) => {
    const columns = [
        {
            field: "username",
            flex: 1,
            renderCell: (parameters: GridRenderCellParams) => (
                <UsernameCell
                    disabled={isEditMode}
                    parameters={parameters}
                    setHoveredRow={setHoveredRow}
                />
            ),
            renderHeader: () => renderHeader("Gebruikers"),
            sortable: false,
        },
        {
            field: "type",
            flex: 0.25,
            renderCell: (parameters: GridRenderCellParams) => (
                <TypeCell
                    disabled={isEditMode}
                    parameters={parameters}
                    setHoveredRow={setHoveredRow}
                />
            ),
            renderHeader: () => renderHeader("Type gebruiker"),
            sortable: false,
        },
        {
            field: "profile",
            flex: 0.2,
            renderCell: (parameters: GridRenderCellParams) => (
                <ProfileCell
                    disabled={isEditMode}
                    handleProfileChange={handleProfileChange}
                    hoveredRow={hoveredRow}
                    parameters={parameters}
                    setHoveredRow={setHoveredRow}
                />
            ),
            renderHeader: () => renderHeader("Profiel"),
            sortable: false,
        },
        {
            field: "informatie",
            flex: 0.15,
            headerName: "",
            renderCell: (parameters: GridRenderCellParams) => (
                <InfoCell
                    disabled={isEditMode}
                    handleToggleDrawer={handleToggleDrawer}
                    hoveredRow={hoveredRow}
                    isEditMode={isEditMode}
                    parameters={parameters}
                    setHoveredRow={setHoveredRow}
                    showInfoIcon={showInfoIcon === parameters.id}
                />
            ),
            sortable: false,
        },
    ];

    return (
        <DataGrid
            checkboxSelection
            columns={columns}
            disableRowSelectionOnClick={isEditMode}
            hideFooter
            rows={rows}
            sx={{
                "& .MuiDataGrid-columnHeaders": {
                    borderBottom: "2px solid #DADADA",
                },
                pointerEvents: isEditMode ? "none" : "auto",
            }}
        />
    );
};

const renderHeader = (headerName: string) => (
    <div style={{ fontFamily: "Open Sans", fontSize: "14px", fontWeight: 600 }}>
        {headerName}
    </div>
);

export default UserOverviewDataGrid;
