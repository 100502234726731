export function numberToColorType(value: number): string {
    if (value === 0) {
        return "inherit";
    }

    if (value > 0) {
        return "success";
    }

    if (value < 0) {
        return "error";
    }

    return "inherit";
}
