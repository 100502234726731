import { Icon, IconName } from "@/theme";
import {
    Drawer,
    DrawerProps,
    Grid2,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";

export function OptionDrawer({
    children,
    description,
    icon,
    title,
    toggleDrawer,
    ...props
}: DrawerProps & {
    children?: React.ReactNode;
    description?: string;
    icon?: IconName;
    title?: string;
    toggleDrawer: () => void;
}) {
    return (
        <Drawer
            anchor="right"
            onClose={() => {
                toggleDrawer();
                return false;
            }}
            sx={{
                "& .MuiDrawer-paper": {
                    borderLeft: "5px solid",
                    borderLeftColor: "#1482CC !important",
                    maxWidth: "40em",
                    overflowY: "scroll",
                    padding: "1em",
                },
            }}
            {...props}
        >
            <Grid2 container>
                <Grid2 size={12}>
                    <Stack
                        direction="row"
                        gap={2}
                        justifyContent={"space-between"}
                    >
                        <Stack direction="row" gap={2}>
                            {icon && <Icon name={icon} size="sm" />}
                            {title && (
                                <Typography variant="h5">{title}</Typography>
                            )}
                        </Stack>
                        <IconButton
                            aria-label="close"
                            onClick={() => toggleDrawer()}
                            role="close"
                        >
                            <Icon name="CloseX" size="sm" />
                        </IconButton>
                    </Stack>
                    {description && <p>{description}</p>}
                </Grid2>
                <Grid2
                    alignItems="stretch"
                    display="flex"
                    justifyContent="space-between"
                    size={12}
                >
                    {children}
                </Grid2>
            </Grid2>
        </Drawer>
    );
}
