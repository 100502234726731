import { getGlobalApiClient } from "@/api";
import { Icon } from "@/theme";
import { Chip, CircularProgress, Menu, Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Components } from "globalapi.openapi";
import { useState } from "react";

import MenuContent from "./menu-content";
import TopMenuButtons from "./top-menu-buttons";

export function SubNavigation() {
    const {
        data: menu,
        isError,
        isPending,
    } = useQuery({
        queryFn: async () => {
            const client = await getGlobalApiClient();
            const res = await client.gmenu_items_organized();
            return res.data;
        },
        queryKey: ["gmenu_items_organized"],
    });

    const [menuKey, setMenuKey] = useState<null | string>(null);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(
        null,
    );
    const open = Boolean(anchorElement);
    const [activeMenu, setActiveMenu] = useState<null | string>(null);
    const [menuContent, setMenuContent] = useState<
        Components.Schemas.MenuStructureResponse[]
    >([]);

    const setMenuContentByKey = (category: string) => {
        if (menu) {
            const menuItem = menu?.menuItems
                ? findMenuItem(menu.menuItems, category)
                : undefined;
            if (menuItem) {
                const parentItem = {
                    menuItem: menuItem.menuItem,
                    menuItems: menuItem.menuItems!,
                };
                setMenuContent([parentItem, ...menuItem.menuItems!]);
            } else {
                setMenuContent([]);
            }
        }
    };

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        key: string,
    ) => {
        const menuCategory = findMenuCategory(key);

        if (!menuCategoryHasChildren(menuCategory)) {
            return;
        }

        setMenuKey(key);
        setMenuContentByKey(key);

        const firstChild = menuCategory
            ? getFirstChildLabel(menuCategory)
            : null;
        setActiveMenu(firstChild);

        if (firstChild) {
            const activeMenuItem = menuCategory
                ? findActiveMenuItem(menuCategory, firstChild)
                : undefined;
            setMenuContent(activeMenuItem?.menuItems ?? []);
        } else {
            setMenuContent([]);
        }

        setAnchorElement(event.currentTarget);
    };

    const findMenuCategory = (key: string) => {
        return menu?.menuItems?.find((item) => item.menuItem?.label === key);
    };

    const handleSubMenuClick = (label: string) => {
        setActiveMenu(label);
        setMenuContentByKey(label);
    };

    const handleClose = () => {
        setAnchorElement(null);
        setActiveMenu(null);
        setMenuKey(null);
        setMenuContent([]);
    };

    const hasMenuItems = menuContent.some(
        (item) => item.menuItems && item.menuItems.length > 0,
    );

    if (isPending) {
        return (
            <Chip
                icon={<CircularProgress size="1rem" />}
                label="Loading menu"
                sx={{ margin: "1em" }}
            />
        );
    }

    if (isError) {
        return (
            <Chip
                color="error"
                icon={<Icon name="Error" />}
                label="Error"
                sx={{ margin: "1em" }}
            />
        );
    }

    return (
        <>
            <Paper elevation={3} square sx={{ flexGrow: 1, height: "48px" }}>
                <TopMenuButtons
                    handleClick={handleClick}
                    menu={menu}
                    menuKey={menuKey}
                />
            </Paper>
            <Menu
                anchorEl={anchorElement}
                id="subnav"
                onClose={handleClose}
                open={open}
            >
                <MenuContent
                    activeMenu={activeMenu}
                    handleSubMenuClick={handleSubMenuClick}
                    hasMenuItems={hasMenuItems}
                    menu={menu}
                    menuContent={menuContent}
                    menuKey={menuKey}
                    setMenuContentByKey={setMenuContentByKey}
                />
            </Menu>
        </>
    );
}

function findActiveMenuItem(
    menuCategory: Components.Schemas.MenuStructureResponse,
    firstChild: string,
) {
    return menuCategory.menuItems?.find(
        (item) => item.menuItem?.label === firstChild,
    );
}

function findMenuItem(
    items: Components.Schemas.MenuStructureResponse[],
    label: string,
): Components.Schemas.MenuStructureResponse | undefined {
    for (const item of items) {
        if (item.menuItem?.label === label) {
            return item;
        }
        if (item.menuItems && item.menuItems.length > 0) {
            const found = findMenuItem(item.menuItems, label);
            if (found) {
                return found;
            }
        }
    }
    return undefined;
}

function getFirstChildLabel(
    menuCategory: Components.Schemas.MenuStructureResponse,
) {
    return menuCategory.menuItems?.[0]?.menuItem?.label ?? null;
}

function menuCategoryHasChildren(
    menuCategory: Components.Schemas.MenuStructureResponse | undefined,
) {
    return (
        menuCategory &&
        menuCategory.menuItems &&
        menuCategory.menuItems.length > 0
    );
}
