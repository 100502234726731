import type { Components } from "globalapi.openapi";

import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SubNavigationTopMenuButton } from "./subnavigation-topmenu-button";

export default function TopMenuButtons({
    handleClick,
    menu,
    menuKey,
}: {
    handleClick: (
        event: React.MouseEvent<HTMLButtonElement>,
        key: string,
    ) => void;
    menu: Components.Schemas.MenuStructureResponse;
    menuKey: null | string;
}) {
    const { t } = useTranslation("root");
    return (
        <Stack direction={"row"}>
            {menu.menuItems?.map((topLevelMenu) => (
                <SubNavigationTopMenuButton
                    active={menuKey === topLevelMenu.menuItem?.label}
                    dropdown={
                        !!(
                            topLevelMenu.menuItems &&
                            topLevelMenu.menuItems.length > 0
                        )
                    }
                    key={topLevelMenu.menuItem?.label}
                    label={t(
                        `mega-menu.${topLevelMenu.menuItem?.i18n_tag}`,
                        topLevelMenu.menuItem?.label ?? "",
                    )}
                    onClick={(event) => {
                        handleClick(event, topLevelMenu?.menuItem?.label ?? "");
                    }}
                />
            ))}
        </Stack>
    );
}
