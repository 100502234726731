import { currencyAtom } from "@/atoms";
import { useAtomValue } from "jotai";

export function Currency({
    signed = false,
    value,
    ...props
}: React.HTMLAttributes<HTMLSpanElement> & {
    signed?: boolean;
    value: number;
}) {
    const currency = useAtomValue(currencyAtom);
    return (
        <span {...props}>
            {signed && value >= 0 && "+"}
            {Intl.NumberFormat(navigator.language, {
                currency: currency,
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }).format(value)}
        </span>
    );
}
