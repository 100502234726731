import companyRoute from "@/routes/company";
import { Paper, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useRouter } from "@tanstack/react-router";
import { Icon } from "@theme/icon";
import { useAtom, useSetAtom } from "jotai";

import { companyIdAtom, companyNameAtom } from "../../atoms";

export function CompanyListButton({
    handleClose,
    id,
    name,
}: {
    handleClose: () => void;
    id: string;
    name: string;
}) {
    const [company, setCompany] = useAtom(companyIdAtom);
    const setCompanyName = useSetAtom(companyNameAtom);
    const router = useRouter();

    return (
        <Paper>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                p={1}
            >
                <Typography>{name}</Typography>
                <Button
                    disabled={company === id}
                    onClick={async () => {
                        setCompany(id);
                        setCompanyName(name);
                        handleClose();
                        await router.navigate(companyRoute);
                    }}
                    size="small"
                    variant="contained"
                >
                    <Icon color="white" name="ChevronRight" size="sm" />
                </Button>
            </Stack>
        </Paper>
    );
}
