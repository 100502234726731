import { Icon } from "@/theme";
import { lightNeutral } from "@/theme/colors";
import { numberToColorType } from "@/utils";
import {
    Button,
    IconButton,
    IconButtonProps,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useState } from "react";

import { Currency } from "../general/currency";
import { Difference } from "../general/difference";

export type BalanceDataMock = {
    categories: BalanceCategory[];
    totals: {
        finalBalancePeriod1: number;
        finalBalancePeriod2: number;
        totalDifference: number;
    };
};

type BalanceCategory = BalanceItem & {
    items: BalanceItem[];
};

type BalanceItem = {
    finalBalancePeriod1: number;
    finalBalancePeriod2: number;
    name: string;
    totalDifference: number;
};

type ExpandMoreProps = IconButtonProps & {
    expand: boolean;
};

export function BalanceRow({
    category,
    last,
}: {
    category: BalanceCategory;
    last: boolean;
}) {
    const [expanded, setExpanded] = useState<boolean>(true);
    return (
        <>
            <TableRow
                onClick={() => {
                    setExpanded(!expanded);
                }}
                sx={{
                    height: last && !expanded ? "100%" : "auto",
                    verticalAlign: last && !expanded ? "top" : "inherit",
                }}
            >
                <TableCell>
                    {category.name}
                    <ExpandMore expand={expanded} size="small" />
                </TableCell>
                <TableCell>
                    <Currency value={category.finalBalancePeriod1} />
                </TableCell>
                <TableCell>
                    <Currency value={category.finalBalancePeriod2} />
                </TableCell>
                <TableCell>
                    <Typography
                        color={numberToColorType(category.totalDifference)}
                        fontSize="0.875rem"
                    >
                        <Currency signed value={category.totalDifference} />
                    </Typography>
                </TableCell>
            </TableRow>
            {category.items.map((item, index, array) => {
                const lastRow = index === array.length - 1;
                return (
                    <TableRow
                        key={item.name}
                        sx={{
                            "& td": { border: 0 },
                            display: expanded ? "table-row" : "none",
                            height: lastRow && last ? "100%" : "auto",
                            verticalAlign: lastRow && last ? "top" : "inherit",
                        }}
                    >
                        <TableCell sx={{ padding: 0, width: "15em" }}>
                            <Typography
                                color="primary"
                                fontSize="0.875rem"
                                m={0}
                                p={0}
                                sx={{
                                    "&:before": {
                                        content: "'•'",
                                        fontSize: "0.875rem",
                                        paddingRight: "0.5em",
                                    },
                                    fontStyle: "italic",
                                    marginLeft: "1em",
                                    width: "15em",
                                }}
                            >
                                {item.name}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Currency value={item.finalBalancePeriod1} />
                        </TableCell>
                        <TableCell>
                            <Currency value={item.finalBalancePeriod2} />
                        </TableCell>
                        <TableCell>
                            <Typography
                                color={numberToColorType(item.totalDifference)}
                                fontSize="0.875rem"
                            >
                                <Currency value={item.totalDifference} />
                            </Typography>
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
}

export function BalanceTable({
    data,
    label,
}: {
    data: BalanceDataMock;
    label: string;
}) {
    return (
        <TableContainer sx={{ height: "100%" }}>
            <Table size="small" sx={{ height: "100%" }}>
                <colgroup>
                    <col width="20%" />
                    <col width="5%" />
                    <col width="5%" />
                    <col width="5%" />
                </colgroup>

                <TableHead>
                    <TableRow>
                        <TableCell>{label}</TableCell>
                        <TableCell>Eindsaldo 2024</TableCell>
                        <TableCell>Eindsaldo 2023</TableCell>
                        <TableCell>Verschil</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.categories.map((category, index, array) => {
                        const lastRow = index === array.length - 1;
                        return (
                            <BalanceRow
                                category={category}
                                key={category.name}
                                last={lastRow}
                            />
                        );
                    })}
                    <BalanceTotalRow data={data} label={label} />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function BalanceTableToolbar({
    toggleDrawer,
}: {
    toggleDrawer: () => void;
}) {
    return (
        <Stack direction="row" gap={2}>
            <Stack direction="row" gap={2}>
                <Typography sx={{ fontWeight: 500 }} variant="h5">
                    Balance
                </Typography>
                <Typography sx={{ fontWeight: 400 }} variant="h5">
                    Eindsaldo van 2024
                </Typography>
            </Stack>
            <Stack direction="row" gap={1}>
                <Button
                    color="info"
                    onClick={() => toggleDrawer()}
                    startIcon={<Icon name="ControlPanel" size="sm" />}
                >
                    Aanpassen
                </Button>
                <IconButton aria-label="print">
                    <Icon name="Print" size="sm" />
                </IconButton>
                <IconButton aria-label="download">
                    <Icon name="Download1" size="sm" />
                </IconButton>
                <IconButton aria-label="close">
                    <Icon name="CloseX" size="sm" />
                </IconButton>
                <Button />
            </Stack>
        </Stack>
    );
}

export function BalanceTotalRow({
    data,
    label,
}: {
    data: BalanceDataMock;
    label: string;
}) {
    return (
        <TableRow
            sx={{
                "& td": {
                    borderBottom: 0,
                    borderTop: "2px solid",
                    borderTopColor: lightNeutral[300],
                },
            }}
        >
            <TableCell>
                <Typography sx={{ fontWeight: 500 }}>
                    Totaal {label.toLowerCase()}
                </Typography>
            </TableCell>
            <TableCell>
                <Difference
                    data-testid="currency-value"
                    sx={{ fontWeight: 500 }}
                    value={data.totals.finalBalancePeriod1}
                />
            </TableCell>
            <TableCell>
                <Difference
                    sx={{ fontWeight: 500 }}
                    value={data.totals.finalBalancePeriod2}
                />
            </TableCell>
            <TableCell>
                <Difference
                    sx={{
                        fontWeight: 500,
                    }}
                    value={data.totals.totalDifference}
                ></Difference>
            </TableCell>
        </TableRow>
    );
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    return (
        <IconButton {...props}>
            <Icon name="ChevronDown" size="sm" />
        </IconButton>
    );
})(({ theme }) => ({
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: "rotate(0deg)",
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: "rotate(180deg)",
            },
        },
    ],
}));
