import type { ListItemButtonProps } from "@mui/material/ListItemButton";

import { Box, List, ListItemButton, Stack, Typography } from "@mui/material";
import { createLink } from "@tanstack/react-router";
import { Components } from "globalapi.openapi";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../../theme/icon";

export function MegaMenuColumn({
    column,
}: {
    column: {
        icon?: string;
        items: Array<Components.Schemas.MenuStructureResponse>;
        title?: string;
    };
}) {
    const { t } = useTranslation("root");
    const ListItemButtonLinkComponent = forwardRef<
        HTMLAnchorElement,
        ListItemButtonProps
    >((props, ref) => {
        return <ListItemButton component={"a"} ref={ref} {...props} />;
    });

    const ListItemButtonLink = createLink(ListItemButtonLinkComponent);

    return (
        <>
            {column.title && (
                <Typography
                    color={"primary"}
                    flexShrink={0}
                    fontSize={"16px"}
                    fontWeight={"bold"}
                    sx={{
                        borderBottom: "1px solid #dadada",
                        marginLeft: "10px",
                        marginRight: "25px",
                        marginTop: "10px",
                        paddingBottom: "0.1em",
                    }}
                    variant="h6"
                >
                    <Stack direction="row" height={"24px"} spacing={1}>
                        {column.icon && (
                            <Box sx={{ paddingTop: "3px" }}>
                                <Icon
                                    name={column.icon as keyof typeof Icon}
                                    size="xs"
                                />
                            </Box>
                        )}
                        <span>{column.title}</span>
                    </Stack>
                </Typography>
            )}
            <List sx={{ paddingRight: "2em" }}>
                {column.items?.map((item, index) => (
                    <ListItemButtonLink
                        key={index}
                        to={item.menuItem?.link ?? "/"}
                    >
                        <Typography color="textPrimary" variant="body2">
                            {item.menuItem &&
                                item.menuItem.label &&
                                t(
                                    `mega-menu.${item.menuItem.i18n_tag}`,
                                    item.menuItem.label,
                                )}
                        </Typography>
                    </ListItemButtonLink>
                ))}
            </List>
        </>
    );
}
