import { User } from "oidc-client-ts";
import { OpenAPIClientAxios } from "openapi-client-axios";
import { z } from "zod";

import type { Client as CommonAPIClient } from "../commonapi.openapi.d.ts";
import type { Client as GlobalAPIClient } from "../globalapi.openapi.d.ts";

const commonAPI = new OpenAPIClientAxios({
    definition: `${import.meta.env.VITE_COMMON_API_URL}/api/v1/openapi.json`,
    withServer: { url: import.meta.env.VITE_COMMON_API_URL },
});

const globalAPI = new OpenAPIClientAxios({
    definition: `${import.meta.env.VITE_GLOBAL_API_URL}/api/v1/openapi.json`,
    withServer: { url: import.meta.env.VITE_GLOBAL_API_URL },
});

const linkSchema = z.object({
    next: z.string().nullable().optional(),
    prev: z.string().nullable().optional(),
    self: z.string(),
});

export const metaSchema = z.object({
    current: z.number(),
    links: linkSchema,
    per_page: z.number(),
});

function getUser() {
    const oidcStorage = localStorage.getItem(
        `oidc.user:${import.meta.env.VITE_OAUTH_AUTHORITY}:${import.meta.env.VITE_OAUTH_CLIENT_ID}`,
    );
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export const getCommonApiClient = async () => {
    const client = await commonAPI.getClient<CommonAPIClient>();
    client.defaults.headers.common.Authorization = `Bearer ${getUser()?.access_token}`;
    return client;
};

export const getGlobalApiClient = async () => {
    const client = await globalAPI.getClient<GlobalAPIClient>();
    client.defaults.headers.common.Authorization = `Bearer ${getUser()?.access_token}`;
    return client;
};
